
import { Component, Prop, Vue } from "vue-property-decorator"

@Component
export default class ResourceFileRow extends Vue {
  @Prop() resource!: any
  @Prop({ default: false }) editable!: boolean
  @Prop() menuActions!: any[]
  @Prop() actions!: { [key: string]: any }

  onMenuActionClick(action: string) {
    this.actions[action]()
  }
}
