
import { Component, Vue, Watch } from "vue-property-decorator"
import AppPage from "@/components/layout/AppPage.vue"
import ResourceCard from "@/components/brand/ResourceCard.vue"
import { FilterType } from "@/components/widgets/common/FilterBar.vue"
import Previewable from "@/components/layout/Previewable.vue"
import Dropzone from "@/components/widgets/input/Dropzone.vue"
import DocumentCard from "@/components/content/DocumentCard.vue"

import {
  UploadFile,
  UploadFileInput,
  BrandResourceVideo,
  BrandResourceDocument,
  BrandResourceCollection,
  BrandResourceLabelsQuery,
  BrandResourceCollectionsQuery,
  ResourceCollectionsSearchQuery,
  CreateBrandResourceVideoMutation,
  UpdateBrandResourceVideoMutation,
  CreateBrandResourceDocumentMutation,
  UpdateBrandResourceDocumentMutation,
  DeleteBrandResourceDocumentMutation,
  UpdateBrandResourceCollectionMutation,
  CreateBrandResourceCollectionMutation,
  DeleteBrandResourceCollectionMutation,
  CreateBrandResourceVideoMutationMutation,
  UpdateBrandResourceVideoMutationMutation,
  DeleteBrandResourceDocumentMutationMutation,
  CreateBrandResourceDocumentMutationMutation,
  UpdateBrandResourceDocumentMutationMutation,
  CreateBrandResourceCollectionMutationMutation,
  DeleteBrandResourceCollectionMutationMutation,
  UpdateBrandResourceCollectionMutationMutation,
} from "@/gql"
import { NetworkStatus } from "@apollo/client/core"

@Component({
  components: {
    AppPage,
    ResourceCard,
    Previewable,
    Dropzone,
    DocumentCard,
  },
  apollo: {
    brandResourceCollections: {
      query: BrandResourceCollectionsQuery,
      variables() {
        return this.queryVars
      },
      result(result) {
        let brandUserGuideCollection: BrandResourceCollection[]
        let videoCollection: BrandResourceCollection[]

        if (result.networkStatus === NetworkStatus.ready) {
          videoCollection = result.data.brandResourceCollections.data.filter(
            (c: BrandResourceCollection) => c.label.name === "Video"
          )

          brandUserGuideCollection = result.data.brandResourceCollections.data.filter(
            (c: BrandResourceCollection) => c.label.name === "Brand User Guide"
          )

          if (brandUserGuideCollection.length) {
            this.brandUserGuideCollection = brandUserGuideCollection.map((collection: any) => {
              return {
                value: collection.id,
                text: collection.name,
              }
            })
            this.brandUserResource = brandUserGuideCollection[0].files?.[0]
          }

          if (videoCollection.length) {
            this.videoCollection = videoCollection.map((collection: any) => {
              return {
                value: collection.id,
                text: collection.name,
              }
            })
            this.videoResource = videoCollection[0].files?.[0]
          }

          this.isLoading = false
          this.errorLoading = false
        }
      },
      update(data) {
        return data.brandResourceCollections.data.filter(
          (c: BrandResourceCollection) => !["Video", "Brand User Guide"].includes(c.label.name)
        )
      },
      error(error) {
        if (error.graphQLErrors) {
          this.errorLoading = true
        } else if (error.networkError) {
          this.networkError = true
        }
      },
    },
  },
})
export default class BrandResources extends Vue {
  readonly BrandResourceLabelsQuery = BrandResourceLabelsQuery
  readonly BrandResourceCollectionsSearchQuery = ResourceCollectionsSearchQuery

  brandResourceCollections: any[] = []
  videoResource: any | null = null
  brandUserResource: BrandResourceDocument | null = null

  isLoading = true
  isSavingResourceDocument = false
  isSavingResourceVideo = false
  isSavingResourceFolder = false
  deleteResourceActionLoading = false
  isDeletingResourceFolder = false

  selectedResource: BrandResourceDocument | null = null
  selectedResourceCollection: BrandResourceCollection | null = null
  selectedVideoResource: BrandResourceVideo | null = null
  selectedUserGuide: BrandResourceDocument | null = null

  videoCollection: BrandResourceCollection | null = null
  brandUserGuideCollection: BrandResourceCollection | null = null

  resourceType: "Video" | "Brand Guide" | "Document" = "Document"

  reassignCollectionId: number | null = null

  uploadedResourceFile: UploadFile | null = null

  editMode = false

  hasUploaderChanged = false

  // Modals
  showInfoModal = false
  showShareModal = false
  showPreviewDialog = false
  showAddVideoResourceModal = false
  showAddResourceFolderModal = false
  showAddResourceModal = false
  showDeleteResourceModal = false
  showDeleteResourceFolderModal = false

  filter: FilterType = {}
  surveyLink = "https://pgi9vfpe6lt.typeform.com/to"

  resourceFolderForm: {
    name: string
    labelId: number | null
  } = {
    name: "",
    labelId: null,
  }

  brandResourceDocumentForm: {
    name: string
    file: UploadFile | UploadFileInput | null
    collectionId: number | null
  } = {
    name: "",
    file: null,
    collectionId: null,
  }

  videoResourceForm: {
    collectionId: number | null
    name: string
    url: string
    videoThumbnail: string
  } = {
    name: "",
    url: "",
    videoThumbnail: "",
    collectionId: null,
  }

  get policy() {
    return {
      manageBrandResources: this.can("manage_brand_resources"),
    }
  }

  @Watch("filter")
  onFilterChange() {
    this.$apollo.queries.brandResourceCollections.refetch()
  }

  toggleEditMode() {
    this.editMode = !this.editMode
  }

  resetDocumentForm() {
    this.hasUploaderChanged = false
    this.brandResourceDocumentForm = {
      name: "",
      file: null,
      collectionId: null,
    }
    this.selectedResource = null
    this.uploadedResourceFile = null
    this.resourceType = "Document"
    this.$refs.observer && (this.$refs.observer as any).reset()
    this.$refs.dropzone && (this.$refs.dropzone as any).reset()
  }

  get canEditResource() {
    return this.editMode && this.policy.manageBrandResources
  }

  get queryVars() {
    return {
      filter: this.filter,
    }
  }

  get resourceCollections() {
    return this.policy.manageBrandResources
      ? this.brandResourceCollections
      : this.brandResourceCollections.filter(
          (collection: any) =>
            collection.label.name !== "Video" && collection.label.name !== "Brand User Guide"
        )
  }

  getFileType(uploadFile: UploadFile) {
    const fileTypeMap: Record<string, string[]> = {
      image: ["jpg", "jpeg", "png", "gif", "svg"],
      video: ["mp4", "mov", "avi", "wmv", "flv", "webm"],
      pdf: ["pdf"],
      word: ["doc", "docx"],
      excel: ["xls", "xlsx"],
      powerpoint: ["ppt", "pptx"],
      text: ["txt"],
      zip: ["zip", "rar", "7z"],
    }

    return Object.keys(fileTypeMap).find((type) => {
      return uploadFile.extension && fileTypeMap[type].includes(uploadFile.extension)
    })
  }

  getFilteredCollections(collections: any) {
    if (!collections) return []
    return collections.data
      .filter((collection: any) => {
        return collection?.id !== this.selectedResourceCollection?.id
      })
      .map((collection: any) => {
        return {
          value: collection.id,
          text: collection.name,
        }
      })
  }

  editBrandGuideResource(resource: any) {
    this.selectedResource = resource
    this.resourceType = "Brand Guide"
    this.showAddResourceModal = true
  }

  @Watch("selectedResource")
  populateResourceForm() {
    this.$refs.dropzone && (this.$refs.dropzone as any).reset()

    if (this.selectedResource) {
      this.brandResourceDocumentForm = {
        name: this.selectedResource.name,
        file: null,
        collectionId: this.selectedResource.brandResourceCollection.id,
      }

      this.uploadedResourceFile = this.selectedResource.uploadFile
    } else {
      this.resetDocumentForm()
    }
  }

  @Watch("selectedResourceCollection")
  populateResorceFolderForm() {
    if (this.selectedResourceCollection) {
      this.resourceFolderForm = {
        name: this.selectedResourceCollection.name,
        labelId: this.selectedResourceCollection.label.id || null,
      }
    } else {
      this.resourceFolderForm = {
        name: "",
        labelId: null,
      }
    }
  }

  @Watch("selectedVideoResource")
  populateVideoResourceForm() {
    if (this.selectedVideoResource) {
      this.videoResourceForm = {
        name: this.selectedVideoResource.name,
        url: this.selectedVideoResource.embedFileUrl,
        videoThumbnail: this.selectedVideoResource.embedFileThumbnail,
        collectionId: this.selectedVideoResource.brandResourceCollection.id,
      }
    } else {
      this.videoResourceForm = {
        name: "",
        url: "",
        videoThumbnail: "",
        collectionId: null,
      }
    }
  }

  getVideoThumbnailUrl(url: string) {
    const videoId = url.split("v=")[1]
    const ampersandPosition = videoId.indexOf("&")
    if (ampersandPosition !== -1) {
      return `https://img.youtube.com/vi/${videoId.substring(0, ampersandPosition)}/0.jpg`
    }

    return `https://img.youtube.com/vi/${videoId}/0.jpg`
  }

  setResourceDocument(docs: UploadFileInput[]) {
    this.brandResourceDocumentForm.file = docs[0]
    this.hasUploaderChanged = true
  }

  async onSaveResourceCollection() {
    if (this.selectedResourceCollection && this.editMode) {
      // update brand collection
      await this.updateResourceCollection()
    } else {
      await this.createResourceCollection()
    }
  }

  async createResourceCollection() {
    if (this.resourceFolderForm.name.length > 0) {
      this.isSavingResourceFolder = true

      await this.mutate<CreateBrandResourceCollectionMutationMutation>({
        mutation: CreateBrandResourceCollectionMutation,
        variables: {
          name: this.resourceFolderForm.name,
          labelId: this.resourceFolderForm.labelId,
        },
        done: () => {
          this.resourceFolderForm = {
            name: "",
            labelId: null,
          }

          this.isSavingResourceFolder = false
          this.showAddResourceFolderModal = false
          this.$apollo.queries.brandResourceCollections.refetch()
        },
      })
    }
  }

  async updateResourceCollection() {
    if (this.resourceFolderForm.name.length > 0) {
      this.isSavingResourceFolder = true

      await this.mutate<UpdateBrandResourceCollectionMutationMutation>({
        mutation: UpdateBrandResourceCollectionMutation,
        variables: {
          name: this.resourceFolderForm.name,
          labelId: this.resourceFolderForm.labelId,
          id: this.selectedResourceCollection?.id,
        },
        done: () => {
          this.resourceFolderForm = {
            name: "",
            labelId: null,
          }

          this.isSavingResourceFolder = false
          this.showAddResourceFolderModal = false
          this.$apollo.queries.brandResourceCollections.refetch()
        },
      })
    }
  }

  async deleteResourceCollection() {
    if (!this.selectedResourceCollection) return

    this.isDeletingResourceFolder = true

    await this.mutate<DeleteBrandResourceCollectionMutationMutation>({
      mutation: DeleteBrandResourceCollectionMutation,
      variables: {
        id: this.selectedResourceCollection.id,
        reassignCollectionId: this.reassignCollectionId || undefined,
      },
      done: () => {
        this.isDeletingResourceFolder = false
        this.showDeleteResourceFolderModal = false
        this.$apollo.queries.brandResourceCollections.refetch()
      },
    })
  }

  async createResourceDocument() {
    if (!this.brandResourceDocumentForm.file) {
      return
    }

    this.isSavingResourceDocument = true

    if (this.brandResourceDocumentForm.name.length > 0 && this.brandResourceDocumentForm.file) {
      const { data } = await this.mutate<CreateBrandResourceDocumentMutationMutation>({
        mutation: CreateBrandResourceDocumentMutation,
        variables: {
          name: this.brandResourceDocumentForm.name,
          uploadFile: this.hasUploaderChanged
            ? this.brandResourceDocumentForm.file
            : this.uploadedResourceFile,
          collectionId:
            this.resourceType === "Brand Guide"
              ? this.brandResourceDocumentForm.collectionId
              : this.selectedResourceCollection!.id,
        },
        done: () => {
          this.$apollo.queries.brandResourceCollections.refetch()
          this.isSavingResourceDocument = false
          this.showAddResourceModal = false

          this.resetDocumentForm()
        },

        error: () => {
          this.isSavingResourceDocument = false
        },
      })

      if (data && !data.createBrandResourceDocument.error) {
        this.addSuccess(`Resource document added successfully!`)
      }
    }
  }

  async updateResourceDocument() {
    this.isSavingResourceDocument = true

    let { file, ...rest } = this.brandResourceDocumentForm

    let requestBody = this.hasUploaderChanged
      ? { ...rest, id: this.selectedResource!.id, uploadFile: this.brandResourceDocumentForm.file }
      : { ...rest, id: this.selectedResource!.id }

    if (this.selectedResource) {
      const { data } = await this.mutate<UpdateBrandResourceDocumentMutationMutation>({
        mutation: UpdateBrandResourceDocumentMutation,
        variables: {
          ...requestBody,
          collectionId:
            this.resourceType === "Brand Guide"
              ? this.brandResourceDocumentForm.collectionId
              : this.selectedResourceCollection!.id,
        },
        done: () => {
          this.$apollo.queries.brandResourceCollections.refetch()
          this.isSavingResourceDocument = false
          this.showAddResourceModal = false
          this.resetDocumentForm()
        },
      })

      if (data && !data.updateBrandResourceDocument.error) {
        this.addSuccess(`Resource document updated successfully!`)
      }
    }
  }

  async createResourceVideo() {
    const validity = await (this.$refs.observer as any).validateWithInfo()

    if (!validity.isValid) {
      return
    }

    this.isSavingResourceVideo = true

    if (this.videoResourceForm.name.length > 0 && this.videoResourceForm.url.length > 0) {
      const { data } = await this.mutate<CreateBrandResourceVideoMutationMutation>({
        mutation: CreateBrandResourceVideoMutation,
        variables: {
          name: this.videoResourceForm.name,
          videoUrl: this.videoResourceForm.url,
          videoThumbnail: this.getVideoThumbnailUrl(this.videoResourceForm.url) || "",
          collectionId: this.videoResourceForm.collectionId,
        },
        done: () => {
          this.$apollo.queries.brandResourceCollections.refetch()
          this.isSavingResourceVideo = false
          this.showAddVideoResourceModal = false
        },

        error: () => {
          this.isSavingResourceVideo = false
        },
      })

      this.videoResourceForm = {
        name: "",
        url: "",
        videoThumbnail: "",
        collectionId: null,
      }

      if (data && !data.createBrandResourceVideo.error) {
        this.addSuccess(`Resource Video added successfully!`)
        this.isSavingResourceVideo = false
      } else {
        this.addError(`Error adding resource video`)
        this.isSavingResourceVideo = false
      }
    }
  }

  async updateResourceVideo() {
    const validity = await (this.$refs.observer as any).validateWithInfo()

    if (!validity.isValid) {
      return
    }

    this.isSavingResourceVideo = true

    if (this.selectedVideoResource && this.videoResourceForm.url.length > 0) {
      const { data } = await this.mutate<UpdateBrandResourceVideoMutationMutation>({
        mutation: UpdateBrandResourceVideoMutation,
        variables: {
          id: this.selectedVideoResource!.id,
          name: this.videoResourceForm.name,
          videoUrl: this.videoResourceForm.url,
          videoThumbnail: this.getVideoThumbnailUrl(this.videoResourceForm.url),
          collectionId: this.videoResourceForm.collectionId,
        },
        done: () => {
          this.$apollo.queries.brandResourceCollections.refetch()
          this.isSavingResourceVideo = false
          this.showAddVideoResourceModal = false

          this.videoResourceForm = {
            name: "",
            url: "",
            videoThumbnail: "",
            collectionId: null,
          }
        },
      })

      if (data && !data.updateBrandResourceVideo.error) {
        this.addSuccess(`Video updated successfully!`)
      }
    }
  }

  async onDeleteResourceDocument() {
    this.deleteResourceActionLoading = true

    if (!this.selectedResource) {
      return
    }

    const { data } = await this.mutate<DeleteBrandResourceDocumentMutationMutation>({
      mutation: DeleteBrandResourceDocumentMutation,
      variables: {
        id: this.selectedResource.id,
      },
      done: () => {
        this.$apollo.queries.brandResourceCollections.refetch()
      },

      error: () => {
        this.deleteResourceActionLoading = false
      },
    })

    if (data && !data.deleteBrandResourceDocument.error) {
      this.addSuccess(`File deleted!`)
      this.showDeleteResourceModal = false
      this.deleteResourceActionLoading = false
      this.selectedResource = null
    }
  }

  onAddBrandGuideBtnClick() {
    this.editMode = false
    this.showAddResourceModal = true
    this.resourceType = "Brand Guide"

    this.brandResourceDocumentForm = {
      name: "Brand User Guide",
      file: null,
      collectionId: null,
    }
  }

  async onSaveResourceDocument() {
    const validity = await (this.$refs.observer as any).validateWithInfo()

    if (!validity.isValid) {
      return
    }

    if (!this.selectedResource) {
      this.createResourceDocument()
    } else {
      this.updateResourceDocument()
    }
  }

  onSaveResourceVideo() {
    if (!this.editMode && !this.selectedVideoResource) {
      this.createResourceVideo()
    } else {
      this.updateResourceVideo()
    }
  }

  onEditVideoResourceClick(resource: any) {
    this.editMode = true
    this.selectedVideoResource = resource
    this.showAddVideoResourceModal = true
  }
}
