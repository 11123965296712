
import { Component, Prop, Vue } from "vue-property-decorator"

import ResourceFileRow from "@/components/brand/ResourceFileRow.vue"
import { BrandResourceFile } from "@/gql"

@Component({
  components: {
    ResourceFileRow,
  },
})
export default class ResourceCard extends Vue {
  @Prop({ required: true }) collection!: any
  @Prop({ required: true }) selectedResourceCollection!: any
  @Prop({ required: true }) selectedResource!: any

  @Prop({ required: true }) showShareModal!: any
  @Prop({ required: true }) showPreviewDialog!: any
  @Prop({ required: true }) editMode!: any

  setSelectedCollection(item: any) {
    this.$emit("update:selectedResourceCollection", item)
  }

  onAddFileBtnClick(item: any) {
    this.$emit("update:showAddResourceModal", true)
    this.$emit("update:selectedResourceCollection", item)
    this.$emit("update:selectedResource", null)
  }

  getResourceActions(resource: BrandResourceFile) {
    const actions: { [key: string]: any } = {}

    actions["Edit"] = () => {
      this.$emit("update:selectedResource", resource)
      this.$emit("update:showAddResourceModal", true)
    }
    actions["Delete"] = () => {
      this.$emit("update:selectedResource", resource)
      this.$emit("update:showDeleteResourceModal", true)
    }
    // TODO: implement shareable link
    // actions["Share"] = () => {
    //   this.$emit("update:selectedResource", resource)
    //   this.$emit("update:showShareModal", true)
    // }
    actions["Preview"] = () => {
      this.$emit("update:selectedResource", resource)
      this.$emit("update:showPreviewDialog", true)
    }

    return actions
  }

  onCollectionAction(action: any, item: any) {
    if (action.toLowerCase() === "edit") {
      this.$emit("update:showAddCollectionModal", true)
      this.$emit("update:selectedResourceCollection", item)
    }
    if (action.toLowerCase() === "delete") {
      this.$emit("update:showDeleteResourceFolderModal", true)
      this.$emit("update:selectedResourceCollection", item)
    }
  }
}
